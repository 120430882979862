var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SecondNavBar'),_c('main',[_c('div',{staticClass:"card shadow col-lg-8 offset-lg-2 mt-4",staticStyle:{"padding-top":"10px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10"},[_c('h3',{staticClass:"text-purple",attrs:{"id":"room-name-title"}},[_vm._v(" "+_vm._s(_vm.roomName)+" - Status: "+_vm._s(_vm.getRoomStatus)+" ")])])]),_vm._m(0),_c('div',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-lg-4 ml-3"},[_vm._v(" Client Street Name: "),_c('span',[_vm._v(_vm._s(_vm.client.streetName))])]),_c('div',{staticClass:"col-lg-6 tan-background-div"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-lg-4 col-md-4"},[_c('h3',{class:{
                    'text-primary': _vm.accessibilityScore === null || _vm.accessibilityScore === undefined || _vm.accessibilityScore === '' || _vm.accessibilityScore === '----' | _vm.accessibilityScore === 'N/A', // Always blue for dashes
                    'text-danger': (_vm.accessibilityScore || _vm.accessibilityScore === 0 || _vm.accessibilityScore === '0') && Math.round(_vm.accessibilityScore) <= 40,
                    'text-warning': Math.round(_vm.accessibilityScore) > 40 && Math.round(_vm.accessibilityScore) <= 79,
                    'text-success': Math.round(_vm.accessibilityScore) >= 80
              }},[_vm._v(" "+_vm._s(_vm.accessibilityScore || _vm.accessibilityScore == 0 || _vm.accessibilityScore == "0" ? Math.round(_vm.accessibilityScore) + "%" : _vm.getRoomStatus.toLowerCase().includes("initial") ? "----" : "N/A")+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('h3',[(_vm.getIfPreassessmentCompleted)?_c('span',{class:{
                    
                    'text-danger': _vm.room.accessibilityPreScore != null && Math.round(_vm.room.accessibilityPreScore) <= 40,
                    'text-warning': _vm.room.accessibilityPreScore != null && Math.round(_vm.room.accessibilityPreScore) > 40 && Math.round(_vm.room.accessibilityPreScore) <= 79,
                    'text-success': _vm.room.accessibilityPreScore != null && Math.round(_vm.room.accessibilityPreScore) >= 80
                  },style:({ fontSize: '0.9rem' })},[_vm._v("(Initial: "+_vm._s(_vm.room.accessibilityPreScore === null ? "n/a" : ((Math.round(_vm.room.accessibilityPreScore)) + "%"))+")")]):_vm._e()])])]),_c('div',{staticClass:"row mt-1"},[_vm._m(2),_c('div',{staticClass:"col-lg-4 col-md-4 text-primary"},[_c('h3',{class:{
                    'text-primary': _vm.safetyScore === null || _vm.safetyScore === undefined || _vm.safetyScore === '' || _vm.safetyScore === '----' || _vm.safetyScore === 'N/A', // Always blue for dashes
                    'text-danger': (_vm.safetyScore || _vm.safetyScore === 0 || _vm.safetyScore === '0') && Math.round(_vm.safetyScore) <= 4,
                    'text-warning': Math.round(_vm.safetyScore) > 4 && Math.round(_vm.safetyScore) <= 7,
                    'text-success': Math.round(_vm.safetyScore) >= 8
              }},[_vm._v(" "+_vm._s((_vm.safetyScore || _vm.safetyScore == 0 || _vm.safetyScore == "0") && _vm.safetyScore !== null ? Math.round(_vm.safetyScore * 10) + "%" : _vm.getRoomStatus.toLowerCase().includes("initial") ? "----" : "N/A")+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('h3',[(_vm.getIfPreassessmentCompleted)?_c('span',{class:{
                    'text-danger': _vm.room.personalSafetyPreScore != null && Math.round(_vm.room.personalSafetyPreScore * 10) <= 40,
                    'text-warning': _vm.room.personalSafetyPreScore != null && Math.round(_vm.room.personalSafetyPreScore * 10) > 40 && Math.round(_vm.room.personalSafetyPreScore * 10) <= 79,
                    'text-success': _vm.room.personalSafetyPreScore != null && Math.round(_vm.room.personalSafetyPreScore * 10) >= 80
                  },style:({ fontSize: '0.9rem' })},[_vm._v("(Initial: "+_vm._s(_vm.room.personalSafetyPreScore === null ? "n/a" : ((Math.round(_vm.room.personalSafetyPreScore * 10)) + "%"))+")")]):_vm._e()])])])])]),_c('RoomForm',{attrs:{"roomName":_vm.roomName,"roomProp":_vm.room,"locationDescription":_vm.locationDescription,"roomGoals":_vm.roomGoals,"roomStatus":_vm.getRoomStatus,"clientId":_vm.clientId,"preAssessmentNotes":_vm.room ? _vm.room.preAssessmentNotes : '',"postAssessmentNotes":_vm.room ? _vm.room.postAssessmentNotes : '',"is-custom-prop":_vm.isCustom},on:{"calculate-accessibility-score":_vm.calculateAccessibilityScore,"calculate-safety-score":_vm.calculateSafetyScore,"set-preassessment-accessibility":_vm.setPreAccessibility,"set-preassessment-safety":_vm.setPreAccessibility,"set-loading":_vm.setLoading}}),_c('div',{staticClass:"vld-parent"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-lg-12"},[_c('p',{staticClass:"text-danger ml-3"},[_vm._v(" NOTE: Select the SAVE button to save room information before navigating to another page. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-8 col-md-7"},[_c('h3',[_vm._v("Accessibility Rating™:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-8 col-md-8"},[_c('h3',[_vm._v("Safety Rating:")])])}]

export { render, staticRenderFns }